import React from 'react';
import { Layout, Button,} from 'antd';
import { Image } from 'react-bootstrap';
import axios from 'axios';
import { SliderMenu, MenuProfile, TopContent, FireFooter } from './../common';
import {CreateUser} from "./components/CreateUser"

import logo from '../../../images/logo.png';

import '../../../styles/dashboard/dashboard-main.scss';
import '../../../styles/dashboard/components/user-component.scss';


import { ListUsers } from './components/ListUsers';
import { EditUser } from './components/EditUser';


const { Header, Content } = Layout;

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('security'),
      profile: null,
      loading: false,
      showUserList: true,
      showUserCreate: false,
      password: undefined,
      confirm: undefined,
      formFull: true,
      formFields: false,
      message: "",
      showForm: true,
      title: "Create",
      editUserId: undefined,
      editUser: undefined,
      users: [],
    }
    this.handleCreate = this.handleCreate.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.openCreate = this.openCreate.bind(this);
  }


  componentDidMount() {
    this.getUsers()
  }

  getUsers() {
    axios.get('/api/v1/user', {
      headers: {
        'Authorization': `Bearer ${this.state.token}`,
      }
    })
      .then((response) => {
        this.setState({ users: response.data.content.map((rw, i) => Object.assign(rw, { number_log: i + 1 })) })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleCreate(){
    this.getUsers()
    this.setState({showUserList: true, showUserCreate: false, editUser: undefined})
  }

  openCreate(user){
    this.setState({showUserCreate: true, showUserList: false, editUser: user})

  }

  cancelForm () {
    this.setState({
      showUserCreate: false,
      showUserList: true,
      formFields: false,
      formFull: true,
      title: "Create"
    })
  }

  render() {
    const { showUserList, showUserCreate,editUser } = this.state

    return (
      <Layout>
        <Header className="header">
          <div className="logo">
            <Image style={{width: '100px'}} src={logo} />
          </div>
          <MenuProfile />
        </Header>
        <Content className="user-component">
          <Layout className="slider-menu" style={{ backgroundColor: '#ccc' }}>
            <SliderMenu selectedMenu="1" />
            {showUserList &&
            <Content className="dashboard" style={{ padding: '0 10px', minHeight: 280, background:'#fafafa' }}>
              <TopContent>
                <span style={{ padding: '20px 0' }}>You can CRUD users</span>
                <Button type="primary" onClick={()=>{ this.openCreate()} }>Create user</Button>
              </TopContent>

              <ListUsers
                data={this.state.users}
                handleEdit={this.openCreate}
              ></ListUsers>
            </Content>}
            {showUserCreate &&
              <Content>
                {editUser ?

                  <EditUser type="edit" user={this.state.editUser} createCallback={this.handleCreate} cancelCallback={this.cancelForm} />
                  :
                  <CreateUser type="create" createCallback={this.handleCreate} cancelCallback={this.cancelForm} />
                }

              </Content>
            }

          </Layout>
        </Content>
        <FireFooter/>
      </Layout>
    )
  }
}

export { Users }
