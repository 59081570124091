import React from 'react'

import { Layout, Button} from 'antd';

import { Image } from 'react-bootstrap';
import { SliderMenu, MenuProfile, TopContent, FireFooter } from './../common';
import { ListEvents } from './components/ListEvents';

import logo from '../../../images/logo.png';

import '../../../styles/dashboard/dashboard-main.scss';
import '../../../styles/dashboard/components/events-component.scss';

import {CreateEvent} from "./components/CreateEvent"

const { Header, Content } = Layout

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('security'),
      showTable: true,
      searchText: '',
      searchedColumn: '',
   }

    this.handleCancel = this.handleCancel.bind(this)
  }

  componentDidMount() {
  }

  handleCancel() {
    this.setState({ showTable: true });
  }

  render() {
    const {showTable} = this.state

    return (
      <Layout>
        <Header className="header">
          <div className="logo">
            <Image style={{ width: '100px' }} src={logo} />
          </div>
          <MenuProfile />
        </Header>

        <Content className="events-component">
          <Layout className="slider-menu" style={{ background: '#ccc' }}>
            <SliderMenu selectedMenu="1" />
            <Content style={{ padding: '0 10px', minHeight: 280,background:'#fafafa'  }}>
              <TopContent>
                <span style={{ padding: '20px 0' }}>You can CRUD events</span>
                <Button type="primary" onClick={() => this.setState({ showTable: false })}>Create Event</Button>
              </TopContent>
              {showTable &&

                <ListEvents props={this.props}/>

              }
              {!showTable &&
                <Content className="createRoom">
                  <CreateEvent props={this.props} handleCancel={this.handleCancel}/>
                </Content>
              }
            </Content>
          </Layout>
        </Content>
        <FireFooter />
      </Layout>
    )
  }
}

export { Events }
