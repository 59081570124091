import React from 'react';
// import { useParams, useLocation } from "react-router";
import { Layout, Menu, Table } from 'antd';
import { Image } from 'react-bootstrap';
import axios from 'axios';
import { SliderMenu, MenuProfile, FireFooter } from './../common';

import logo from '../../../images/logo.png';

import '../../../styles/dashboard/dashboard-main.scss';

import { ListBusinesses } from './components/ListBusinesses';


const { Header, Content } = Layout;

class Business extends React.Component {
    state = {
        token: localStorage.getItem('security'),
        selectedRowKeys: [],
        offices: [],
    };


    componentDidMount() {
        axios.get('/api/v1/office', {
            'Authorization': 'Bearer ' + this.state.token
        })
        .then((response) => {
            this.setState({ offices: response.data.content.map((rw, i) => Object.assign(rw, { number_log: i + 1 })) });
        })
        .catch((error) => {
            console.log(error);
        });
    }


    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };


    render() {
        const { offices } = this.state;

        // const rowSelection = {
        //   selectedRowKeys,
        //   onChange: this.onSelectChange,
        // }

        return (
            <Layout>
                <Header className="header">
                    <div className="logo">
                        <Image style={ { width: '100px' } } src={ logo }/>
                    </div>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={ ['2'] }
                        style={ { lineHeight: '64px' } }
                    >
                    </Menu>
                    <MenuProfile/>
                </Header>
                <Content>
                    <Layout className="slider-menu" style={ { background: '#ccc' } }>
                        <SliderMenu selectedMenu="2"/>

                        <Content style={ { minHeight: 280 } }>
                            <ListBusinesses data={ offices }></ListBusinesses>
                        </Content>
                    </Layout>
                </Content>
                <FireFooter/>
            </Layout>
        );
    }
}

export { Business };
